import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image";

const SecondPage = () => (
    <Layout>
        <Seo title="References" />
        <div className="bg-white">
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="relative py-24 px-8 bg-green-500 rounded-xl shadow-2xl overflow-hidden lg:px-16 lg:grid lg:grid-cols-2 lg:gap-x-8">
                    <div className="absolute inset-0 opacity-50 filter saturate-0 mix-blend-multiply">
                        <StaticImage src={"../images/zeus.png"} alt={'Screenshot of Zeus system'} className="w-full h-full r" />
                    </div>
                    <div className="relative lg:col-span-1">
                        <blockquote className="mt-6 text-white">
                            <p className="text-xl font-medium sm:text-2xl">
                                A complete system for scheduling orders and visits for an appliance repair shop.
                            </p>
                            <footer className="mt-6">
                                <p className="flex flex-col font-medium">
                                    <span>Frontend</span>
                                    <span>Backend</span>
                                </p>
                            </footer>
                        </blockquote>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg-white  pt-6">
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="relative py-24 px-8 bg-indigo-500 rounded-xl shadow-2xl overflow-hidden lg:px-16 lg:grid lg:grid-cols-2 lg:gap-x-8">
                    <div className="absolute inset-0 opacity-50 filter saturate-0 mix-blend-multiply">
                        <StaticImage src={"../images/oneofmore.png"} alt={'Screenshot of Zeus system'} className="w-full h-full r" />
                    </div>
                    <div className="relative lg:col-span-1">
                        <blockquote className="mt-6 text-white">
                            <p className="text-xl font-medium sm:text-2xl">
                                A small simple website for independent film.
                            </p>
                            <footer className="mt-6">
                                <p className="flex flex-col font-medium">
                                    <span>Frontend</span>
                                    <span>Gatsby</span>
                                </p>
                            </footer>
                        </blockquote>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg-white pt-6">
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="relative py-24 px-8 bg-blue-900 rounded-xl shadow-2xl overflow-hidden lg:px-16 lg:grid lg:grid-cols-2 lg:gap-x-8">
                    <div className="absolute inset-0 opacity-50 filter saturate-0 mix-blend-multiply">
                        <StaticImage src={"../images/typag.png"} alt={'Screenshot of Zeus system'} className="w-full h-full r" />
                    </div>
                    <div className="relative lg:col-span-1">
                        <blockquote className="mt-6 text-white">
                            <p className="text-xl font-medium sm:text-2xl">
                                Information system for managing orders, tenders and invoicing.
                            </p>
                            <footer className="mt-6">
                                <p className="flex flex-col font-medium">
                                    <span>Frontend</span>
                                    <span>Backend</span>
                                </p>
                            </footer>
                        </blockquote>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default SecondPage
